import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import imgRyan from "../../assets/image/jpeg/ryan.jpg";
import imgBrian from "../../assets/image/jpeg/brian.jpg";
import imgBen from "../../assets/image/jpeg/ben.jpg";
import imgEngin from "../../assets/image/jpeg/engin.jpg";
import imgElliott from "../../assets/image/jpeg/elliott.jpg";
import imgZak from "../../assets/image/jpeg/zak.jpg";
import imgRaghu from "../../assets/image/jpeg/raghu.jpg";
import imgMax from "../../assets/image/jpeg/max.jpg";
import imgMichael from "../../assets/image/jpeg/michael.jpg";
import imgArthur from "../../assets/image/jpeg/arthur.jpg";
import imgPreethi from "../../assets/image/jpeg/preethi.jpg";
import imgNuno from "../../assets/image/jpeg/nuno.jpg";
import imgPeter from "../../assets/image/jpeg/peter.jpg";
import imgJon from "../../assets/image/jpeg/jon.jpg";

const CardImage = styled.div`
  max-width: 200px;
  min-width: 200px;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  border-radius: 500px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 25px;
`;

const TeamCard = ({ userImg, title, children, ...rest }) => (
  <Box
    className="text-center"
    pt="15px"
    px="30px"
    borderRadius={10}
    mb={4}
    {...rest}
  >
    <CardImage>
      <img src={userImg} className="img-fluid" alt="" />
    </CardImage>
    <div className="text-center">
      <Title variant="card" fontSize="20px" letterSpacing={-0.75} my={1}>
        {title}
      </Title>
      <Text variant="small" fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </Box>
);

const Team = () => (
  <>
    {/* <!-- Team section --> */}
    <Section className="position-relative pt-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg="6" className="text-center pb-3">
            <div className="">
              <Title>Meet the Team</Title>
              <Text>
                The experienced, distributed development and technology team
                behind Alkemi Network.
              </Text>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgRyan} title="Ryan Breen">
              Founder & CEO
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgBrian} title="Brian Mahoney">
              Co-Founder & CSO
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgBen} title="Ben Cooper">
              Co-Founder & CXO
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgEngin} title="Engin Erdogan">
              Head of Ecosystem & Growth
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgMichael} title="Michael Nash">
              Head of Operations
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgJon} title="Jonathan Ip">
              Legal Counsel
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgPeter} title="Peter Gray">
              Integrations & Partnerships
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgMax} title="Max Luck-Hille">
              Marketing & Brand Manager
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgArthur} title="Arthur Zubkoff">
              Community Manager
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgElliott} title="Elliott Brown">
              Engineering Manager
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgZak} title="Zakarya L">
              Full Stack Engineer
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgRaghu} title="Raghu Thiyagharajan">
              Distributed Systems Engineer
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgPreethi} title="Preethi Senthil">
              Distributed Systems Engineer
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="3" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgNuno} title="Nuno Cervaens">
              Backend Engineer
            </TeamCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Team;

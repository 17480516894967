import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import { Title, Section, Box, Text } from '../../components/Core'

import imgContent1 from '../../assets/image/jpeg/alkemi-about1.jpg'
import imgContent2 from '../../assets/image/jpeg/alkemi-about2.jpg'

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`
const Content = () => {

  return (
    <>
      <Section>
        <Container>
          <Row className="justify-content-center pb-4">
            <Col lg="6">
              <Title variant="pagetitle">Bridging CeFi with DeFi</Title>
            </Col>
            <Col lg="6" className="pl-lg-5">
              <Text>
                Our technology bridges the currently separated worlds of Centralized and Decentralized Finance.
                Launching with a core team of globally-distributed, specialized developers and designers, Alkemi Network
                is building an on-chain liquidity network with a suite of tools and products that serve as onramps
                for everyone to participate in DeFi.
              </Text>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg="4" sm="5" className="mb-4 ">
              <ContentImg>
                <img src={imgContent1} alt="" className="img-fluid" />
              </ContentImg>
            </Col>
            <Col lg="8" sm="7" className="mb-4">
              <ContentImg>
                <img src={imgContent2} alt="" className="img-fluid" />
              </ContentImg>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Content

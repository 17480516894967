import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import { Title, Button, Section, Box, Text } from '../../components/Core'

import svgCurve from '../../assets/image/svg/l1-curve-cta.svg'

const RightCard = styled(Box)`
  position: absolute;
  top: 0;
  right: -275px;
`

const CTA = () => (
  <>
    {/* <!-- CTA section --> */}
    <Section bg="ash" className="position-relative">
      <RightCard>
        <img src={svgCurve} alt="" className="img-fluid" />
      </RightCard>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="6">
            <Box mb={5} className="text-center">
              <Title color="light">Job Opportunities</Title>
              <Text color="light" opacity={0.7}>
                Are you passionate about DeFi? We are often looking for talented
                and experienced people to join our team.
              </Text>
            </Box>
            <Box>
            <a href="https://angel.co/company/alkemi/jobs" target="_blank" rel="noreferrer">
              <Button>Check Job Openings</Button>
              </a>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
)

export default CTA

import React from "react";

import PageWrapper from "../components/PageWrapper";
import PageTitle from "../sections/common/PageTitle";
import Content from "../sections/about/Content";
import Team from "../sections/about/Team";
import Cta from "../sections/about/CTA";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Alkemi Network</title>
        <meta
          name="description"
          content="Alkemi Network is building an on-chain liquidity network with a suite of tools and products that serve as onramps for everyone to participate in DeFi."
        />
        <meta
          property="og:title"
          content="About Us - Bridging CeFi to DeFi | Alkemi Network"
        />
        <meta
          property="og:description"
          content="Alkemi Network is building an on-chain liquidity network with a suite of tools and products that serve as onramps for everyone to participate in DeFi."
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content="https://alkemi.network/about/" />
        <link rel="canonical" href="https://alkemi.network/about/" />
      </Helmet>
      <PageWrapper footerDark>
        <PageTitle title="Alkemi Network">
          Alkemi Network was born out of the fusion of extensive DeFi knowledge
          and CeFi expertise.
        </PageTitle>
        <Content />
        <Team />
        <Cta />
      </PageWrapper>
    </>
  );
};
export default About;
